<template>
    <ListaUsuarios />
</template>

<script>
import ListaUsuarios from '@/components/Usuario/ListaUsuario'
export default {
  components: {ListaUsuarios},
  data(){
    return{}
  }
}
</script>

<style>

</style>